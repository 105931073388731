<template>
  <div class="clientFooter">
    <div class="footer-content">
      <div class="left">
        <img src="/img/plogot.png" alt="" srcset="" />
        <p>
          Explore the beauty of Rwanda and East Africa with Pride Adventure. 
          From breathtaking safaris to unforgettable cultural experiences, 
          we create tailor-made journeys for every traveler.
        </p>
      </div>
      <div class="middle">
        <label for="Quick Links"> Quick Links</label>
        <ul class="navs">
          <li><router-link to="/" class="nav-item">Home</router-link></li>
          <li>
            <router-link to="/tours" class="nav-item">Tours</router-link>
          </li>
          <li>
            <router-link to="/hotels" class="nav-item">Hotels</router-link>
          </li>
          <li>
            <router-link to="/destinations" class="nav-item"
              >Destinations</router-link
            >
          </li>
          <li>
            <router-link to="/contact" class="nav-item">Contact Us</router-link>
          </li>
        </ul>
      </div>
      <div class="right">
        <label for="contact us">Reach Us Through</label>
        <ul>
          <li>
            <i class="fa fa-phone"></i> +250 788 518 602
          </li>
          <li><i class="fa fa-envelope"></i>prideadventure0@gmail.com</li>
        </ul>
        <div class="socials">
          <a
            href="https://www.facebook.com/prideadventure/?ref=page_internal"
            target="_blank"
            ><i class="fab fa-facebook"></i
          ></a>
          <a href="https://www.instagram.com/pride.adventure/" target="_blank"
            ><i class="fab fa-instagram"></i
          ></a>
          <a
            href="https://www.youtube.com/channel/UCm0SoVIr462k314sMTOJLBQ"
            target="_blank"
            ><i class="fab fa-youtube"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>
        © 2025 Copyrights by&copy; <strong>Pride Adventure</strong>  All Rights Reserved
        <a href=""></a>
      </p>
    </div>
    <!-- <a
      href="https://api.whatsapp.com/send/?phone=250788232465"
      target="_blank"
      class="help"
      data-aos="fade-up"
      data-aos-duration="3000"
      data-aos-delay="300"
    >
      <i class="fas fa-comment-alt"></i>
    </a> -->
  </div>
</template>

<script>
export default {
  name: "clientFooter",
};
</script>

<style lang="scss" scoped>
@import "@/scss/main.scss";
.clientFooter {
  .footer-content {
    width: 99.1vw;
    overflow: hidden;
    background: #1d1d1d;
    padding: 3rem 15rem;
    align-items: flex-start;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    @media screen and (max-width: 1700px) {
      padding: 3rem 10rem;
    }
    @media screen and (max-width: 1300px) {
      padding: 3rem 5rem;
    }
    @media screen and (max-width: 900px) {
      padding: 2rem 3rem;
      width: 100vw;
      grid-template-columns: 100%;
    }
    .left {
      @media screen and (max-width: 900px) {
        border-bottom: 1px #ffffff65 solid;
        margin-bottom: 1rem;
      }
      img {
        width: 150px;
      }
      p {
        font-size: 0.9rem;
        color: white;
        width: 70%;
        margin-top: 1rem;
      }
    }
    .middle {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      @media screen and (max-width: 900px) {
        border-bottom: 1px #ffffff65 solid;
        margin-bottom: 1rem;
      }
      label {
        font-weight: 600;
        font-size: 1.2rem;
        color: #fff;
      }
      ul {
        list-style: none;
        line-height: 2;
        .nav-item {
          color: white;
          font-size: 0.9rem;
          &:hover {
            color: $primary_color;
          }
          &:hover:after {
            color: $primary_color;
            transform: scaleX(1);
            transform-origin: bottom left;
          }
          &.router-link-exact-active {
            color: $primary_color;
          }
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: $primary_color;
            transform-origin: bottom right;
            transition: transform 0.4s ease-in-out;
          }
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      row-gap: 0.2rem;
      label {
        font-weight: 600;
        font-size: 1.2rem;
        color: #fff;
        margin-bottom: 0.8rem;
      }
      ul {
        list-style: none;
        font-size: 0.9rem;
        color: #fff;
        line-height: 2;
        i {
          color: $primary_color;
          margin-right: 0.5rem;
        }
      }
      .socials {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        column-gap: 1rem;
        a {
          color: #fff;
          &:hover {
            color: $primary_color;
          }
        }
      }
    }
  }
  .copyright {
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    background: #1d1d1d;
    border-top: 1px #3b3b3b dashed;
    p {
      color: white;
      font-weight: 200;
      font-size: 0.9rem;
      strong {
        text-transform: uppercase;
      }
      a {
        color: $primary_color;
        font-weight: 500;
      }
    }
  }
}
</style>
