<template>
  <div class="clientHeader">
    <div class="header-content">
      <section class="topbar" :class="scroll ? 'scroll' : ''">
        <router-link to="/" class="left">
          <img src="/img/plogot.png" alt="Pride Adventure" srcset="" />
        </router-link>
        <div class="right">
          <div class="navbar">
            <ul class="nav-links">
              <li class="link-item">
                <router-link to="/" class="link-a">Home</router-link>
                <!-- <router-link to="/services" class="link-a"
                  >Services</router-link
                > -->
                <router-link :to="{ name: 'About' }" class="link-a"
                  >About</router-link
                  >
                <router-link :to="{ name: 'Tours' }" class="link-a"
                  >Tours</router-link
                >
                <!--router-link :to="{ name: 'Hotels' }" class="link-a"
                  >Hotels</router-link
                > -->
                <!--router-link :to="{ name: 'Stays' }" class="link-a"
                  >Stays</router-link
                > -->
                <router-link to="/destinations" class="link-a"
                  >Destinations</router-link
                >
                <router-link :to="{ name: 'Blog' }" class="link-a"
                  >Blog</router-link
                >
                <router-link to="/contact" class="link-a">Contact</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="right-right">
          <div class="contact">
            <i class="fa-solid fa-paper-plane"></i>
            <div class="text">
              <p>For More Enquiries</p>
              <label>+250 788 518 602</label>
            </div>
          </div>
        </div>

        <button class="lines" @click="leftbar = true">
          <i class="fas fa-bars"></i>
        </button>
      </section>
      <section class="sidebar" v-if="leftbar">
        <div class="sidebar-body">
          <div class="sidebar-header">
            <label for="Pride Adventure">Pride Adventure</label>
            <button @click="leftbar = false">&times;</button>
          </div>
          <ul class="side-links">
            <li><router-link class="side-link" to="/">Home</router-link></li>
            
            <li><router-link :to="{ name: 'About' }" class="side-link" >About</router-link ></li>  
            <li><router-link class="side-link" to="/tours">Tours</router-link></li>
            <!--li><router-link class="side-link" to="/hotels">Hotels</router-link></li-->
            <!--li><router-link class="side-link" to="/stays">Stays</router-link></li-->
            <li><router-link class="side-link" to="/destinations">Destinations</router-link></li>
            <li><router-link class="side-link" to="/Blog">Blog</router-link></li>
            <li><router-link class="side-link" to="/contact">Contact Us</router-link></li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "clientHeader",
  data() {
    return {
      leftbar: false,
      scroll: false,
    };
  },
  methods: {},
  mounted() {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        this.scroll = true;
      } else {
        this.scroll = false;
      }
    });
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/main.scss";
* {
  transition: all 0.4s ease-in;
}
.clientHeader {
  width: 99.1vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  // box-shadow: 0px 1px 10px 1px #00000034;
  // background: white;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.568) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  .header-content {
    .topbar {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 15rem;
      align-items: center;
      @media screen and (max-width: 1700px) {
        padding: 0.5rem 10rem;
      }
      @media screen and (max-width: 1300px) {
        padding: 0.5rem 5rem;
      }
      @media screen and (max-width: 900px) {
        padding: 0.5rem 2rem;
      }
      .left {
        img {
          width: 100px;
          @media screen and (max-width: 1200px) {
            width: 60px;
          }
          @media screen and (max-width: 1000px) {
            width: 50px;
          }
          @media screen and (max-width: 750px) {
            width: 40px;
          }
        }
      }
      .right {
        margin-bottom: -10px;
        @media screen and (max-width: 750px) {
          display: none;
        }
        .navbar {
          .nav-links {
            list-style: none;
            display: flex;
            .link-item {
              .link-a {
                padding: 0.8rem 1rem;
                color: #fff;
                font-weight: 600;
                position: relative;
                text-transform: uppercase;
                &:after {
                  content: "";
                  position: absolute;
                  width: 0%;
                  transform: scaleX(0);
                  height: 1px;
                  bottom: 0;
                  left: 0;
                  transform-origin: bottom right;
                  transition: transform 0.4s ease-in-out;
                  background: $secondary_color;
                  margin-left: 1rem;
                }
                @media screen and (max-width: 1200px) {
                  padding: 0.8rem 0.5rem;
                  font-size: 0.9rem;
                }
                @media screen and (max-width: 1000px) {
                  padding: 0.8rem 0.5rem;
                  font-size: 0.75rem;
                  font-weight: 500;
                }
                &:hover {
                  &:after {
                    content: "";
                    position: absolute;
                    width: 25px;
                    transform: scaleX(1);
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    transform-origin: bottom right;
                    transition: transform 0.4s ease-in-out;
                    background: $secondary_color;
                  }
                }
                &.router-link-exact-active {
                  &:after {
                    content: "";
                    position: absolute;
                    width: 25px;
                    transform: scaleX(1);
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    transform-origin: bottom right;
                    transition: transform 0.4s ease-in-out;
                    background: $secondary_color;
                  }
                }
              }
            }
          }
        }
      }
      .right-right {
        display: block;
        @media screen and (max-width: 750px) {
          display: none;
        }
        .contact {
          display: flex;
          align-items: center;
          i {
            font-size: 1.5rem;
            color: $secondary_color;
            border-right: 1px #979797 solid;
            padding-right: 0.5rem;
            @media screen and (max-width: 1200px) {
              font-size: 1.25rem;
            }
            @media screen and (max-width: 1000px) {
              font-size: 1.1rem;
            }
          }
          .text {
            margin-left: 0.5rem;
            p {
              font-size: 0.8rem;
              margin: 0;
              color: $secondary_color;
              @media screen and (max-width: 1200px) {
                font-size: 0.7rem;
              }
              @media screen and (max-width: 1000px) {
                font-size: 0.6rem;
              }
            }
            label {
              font-size: 1rem;
              color: #fff;
              font-weight: 700;
              @media screen and (max-width: 1200px) {
                font-size: 0.9rem;
              }
              @media screen and (max-width: 1000px) {
                font-size: 0.8rem;
                font-weight: 500;
              }
            }
          }
        }
      }
      .lines {
        background: none;
        font-size: 1.25rem;
        border: none;
        outline: none;
        background: #ff6f0f;
        padding: 0 10px;
        box-shadow: 0px 0px 5px 10px #00000025 inset;
        color: white;
        border-radius: 3px;
        cursor: pointer;
        @media screen and (min-width: 750px) {
          display: none;
        }
      }
      &.scroll {
        background: #fff;
        border-bottom: 1px #eee solid;
        .right {
          .navbar {
            .nav-links {
              .link-item {
                .link-a {
                  color: #000;
                  &:after {
                    background: $primary_color;
                  }
                  &.router-link-exact-active {
                    color: $primary_color;
                  }
                }
              }
            }
          }
        }
        .right-right {
          .contact {
            i {
              color: $primary_color;
            }
            .text {
              p {
                color: #000;
              }
              label {
                color: $primary_color;
              }
            }
          }
        }
      }
    }
    .sidebar {
      position: fixed;
      z-index: 99;
      background: white;
      width: 50vw;
      height: 100vh;
      top: 0;
      left: 0;
      box-shadow: 5px 0px 10px 10px #0000005b;
      @media screen and (min-width: 750px) {
        display: none;
      }
      @media screen and (max-width: 450px) {
        width: 80vw;
      }
      .sidebar-body {
        display: flex;
        flex-direction: column;
        .sidebar-header {
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          align-items: center;
          label {
            font-size: 0.9rem;
            color: #000;
          }
          button {
            background: none;
            font-size: 1.25rem;
            border: none;
            outline: none;
            background: #eee;
            padding: 0 10px;
            box-shadow: 0px 0px 5px 10px #00000025 inset;
            cursor: pointer;
          }
        }
        .side-links {
          list-style: none;
          display: flex;
          flex-direction: column;
          li {
            display: block;
            .side-link {
              padding: 0.5rem 1rem;
              color: #00051b;
              font-size: 0.9rem;
              display: block;
              border-bottom: 1px #eee solid;
              &:hover {
                background: $secondary_color;
                color: white;
              }
              &.router-link-exact-active {
                background: $secondary_color;
                color: white;
              }
            }
          }
        }
      }
    }
  }
}
</style>
