<template>
  <div class="Contact">
    <clientHeader />
    <div class="all-content">
      <div class="intro">
        <div class="intro-content">
          <div class="intro-body">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              class="intro-header"
            >
              Contact Us
            </h2>
            <p
              class="txt-shad-sm intro-desc"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Home / <strong>Contact</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="body">
        <div class="body-header"><h2>Get in touch with us</h2></div>
        <div class="left">
          <div class="left-title">Stay in touch</div>
          <div class="left-items">
            <div class="left-item">
              <div class="icon">
                <i class="fa fa-phone"></i>
              </div>
              <div class="info">
                <div>
                  <b>Phone:</b> <span>+250 788 518 602</span>
                </div>
              </div>
            </div>
            <div class="left-item">
              <div class="icon">
                <i class="fa fa-envelope"></i>
              </div>
              <div class="info">
                <div>
                  <b>Email:</b>
                  <span>prideadventure0@gmail.com</span>
                </div>
              </div>
            </div>
            <div class="left-item">
              <div class="icon">
                <i class="fa fa-map-marker"></i>
              </div>
              <div class="info">
                <div>
                  <b>Address:</b>
                  <span> 1 KN 78 St, Kigali, Rwanda</span>
                </div>
              </div>
            </div>
            <div class="left-item">
              <div class="icon">
                <i class="fa fa-clock"></i>
              </div>
              <div class="info">
                <div>
                  <b>Office schedule:</b>
                  <span>8:00 AM - 6:00 PM</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <form @submit.prevent="contactNow">
            <h2>Contact form</h2>
            <div class="form-group">
              <label for="names">Fullname:</label>
              <input
                type="text"
                name="fullname"
                v-model="contacts.fullname"
                id=""
                placeholder="fullname"
              />
            </div>
            <div class="form-group">
              <label for="names">Email Address:</label>
              <input
                type="text"
                name="email"
                id=""
                v-model="contacts.email"
                placeholder="email"
              />
            </div>
            <div class="form-group">
              <label for="names">Subject:</label>
              <input
                type="text"
                name="subject"
                id=""
                v-model="contacts.subject"
                placeholder="subject"
              />
            </div>
            <div class="form-group">
              <label for="names">Message:</label>
              <textarea
                name="message"
                id=""
                cols="30"
                rows="10"
                placeholder="message"
                v-model="contacts.message"
              ></textarea>
            </div>
            <div class="form-group">
              <input type="submit" value="Send" class="mybtn" />
            </div>
          </form>
        </div>
      </div>
    </div>
    <clientFooter />
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      isLoading: false,
      color: "#072e4d",
      fullPage: true,
      contacts: {
        fullname: "",
        email: "",
        subject: "",
        message: "",
      },
    };
  },
  methods: {
    onCancel() {
      console.log();
    },
    contactNow() {
      let vm = this;
      if (
        vm.contacts.fullname &&
        vm.contacts.email &&
        vm.contacts.subject &&
        vm.contacts.message
      ) {
        vm.isLoading = true;
        vm.$store
          .dispatch("CONTACT_NOW", vm.contacts)
          .then((response) => {
            if (response.data.status == "ok") {
              vm.$notify({
                group: "status",
                title: "Important message",
                text: response.data.message,
                type: "success",
              });
              vm.contacts.fullname = null;
              vm.contacts.email = null;
              vm.contacts.subject = null;
              vm.contacts.message = null;
              vm.isLoading = false;
            }
          })
          .catch((error) => {
            console.error({
              error,
            });
            vm.isLoading = false;
          });
      } else {
        vm.$notify({
          group: "status",
          title: "Warning message",
          text: "All fields are required",
          type: "warn",
        });
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/scss/main.scss";
.Contact {
  width: 99.1vw;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100vw;
  }
  .body {
    display: grid;
    grid-template-columns: 1fr;
    padding: 3rem 15rem;
    @media screen and (max-width: 1700px) {
      padding: 4rem 10rem;
    }
    @media screen and (max-width: 1300px) {
      padding: 3rem 5rem;
    }
    @media screen and (max-width: 900px) {
      padding: 2rem;
    }
    @media screen and (max-width: 900px) {
      display: grid;
      grid-template-columns: 96%;
      row-gap: 2%;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
    .body-header {
      font-size: 2rem;
      font-weight: 600;
      color: $primary_color;
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      h2 {
        font-size: 2rem;
        font-weight: 600;
        color: #000;
        border-bottom: 1px solid $primary_color;
        line-height: 2;
      }
    }
    .left {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 2rem;
      @media screen and (max-width: 900px) {
        grid-template-columns: 96%;
        row-gap: 2%;
      }
      .left-title {
        font-size: 1.5rem;
        font-weight: 600;
        color: #000;
        margin-bottom: 1rem;
      }
      .left-items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 2rem;
        .left-item {
          display: grid;
          grid-template-columns: 1fr;
          grid-row-gap: 1rem;
          box-shadow: 0px 0px 10px 3px #4d4d4d2a;
          padding: 1rem;
          border-radius: 1rem;

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              font-size: 1.5rem;
              color: $primary_color;
            }
          }
          .info {
            div {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 0.5rem;
              font-size: 1rem;
              font-weight: 400;
              color: #333;
              span {
                font-size: 0.9rem;
                font-weight: 400;
                color: #333;
              }
            }
          }
        }
      }
    }
    .right {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 2rem;
      margin-top: 4rem;
      @media screen and (max-width: 900px) {
        grid-template-columns: 96%;
        row-gap: 2%;
      }
      form {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 2rem;
        h2 {
          font-size: 1.5rem;
          font-weight: 600;
          color: #000;
          margin-bottom: 1rem;
        }
        .form-group {
          display: grid;
          grid-template-columns: 1fr;
          grid-row-gap: 1rem;
          label {
            font-size: 1rem;
            font-weight: 600;
            color: #000;
          }
          input,
          textarea {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 0.5rem;
            font-size: 1rem;
            font-weight: 400;
            color: #333;
            &:focus {
              outline: none;
              border: 1px solid $primary_color;
            }
          }
          input[type="submit"] {
            background-color: $primary_color;
            color: white;
            font-size: 1rem;
            font-weight: 600;
            padding: 0.5rem;
            border: none;
            border-radius: 0.5rem;
            cursor: pointer;
            &:hover {
              background-color: $secondary_color;
            }
          }
        }
      }
    }
  }
}
</style>
