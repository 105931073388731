<template>
  <div class="home">
    <clientHeader />
    <div class="all-content">
      <div class="jumbo2">
        <div class="jumbo-section">
          <Carousel :wrapAround="true" :itemsToShow="1" :itemsToScroll="1" :transition="1000" :snapAlign="'center'" autoplay="4000"
            class="jumboimg-body" v-if="slides.length > 0" v-model="currentSlide">
            <CouSlide class="jumboimg" v-for="slide in slides" :key="slide.id">
              <div class="img">
                <img :src="$uploads(slide.image)" alt="Pride Adventure" />
              </div>
              <div class="text-content">
                <div class="text">
                  <h2>{{ slide.title }}</h2>
                  <p>{{ slide.caption }}</p>
                </div>
              </div>
            </CouSlide>
          </Carousel>
        </div>
        
        <div class="flex-c">
          <div class="filter-section">
            <div class="top-filter">
              <div class="filter-btns">
                <button class="mybtn" :class="activeFilterTab == 'Tours' ? 'active' : ''"
                  @click="activeFilterTab = 'Tours'">
                  <i class="fas fa-globe"></i>
                  Tours
                </button>
                <button class="mybtn" :class="activeFilterTab == 'Hotels' ? 'active' : ''"
                  @click="activeFilterTab = 'Hotels'">
                  <i class="fas fa-hotel"></i>
                  Hotels
                </button>
                <button class="mybtn" :class="activeFilterTab == 'Stays' ? 'active' : ''"
                  @click="activeFilterTab = 'Stays'">
                  <i class="fas fa-house"></i>
                  Stays
                </button>
              </div>
            </div>
            <div class="bottom-filter">
              <div class="filter">
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  <div class="inputs">
                    <label for="Destination">Destination</label>
                    <input type="text" placeholder="Where are you going?" />
                  </div>
                </div>
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-calendar-alt"></i>
                  </div>
                  <div class="inputs">
                    <label for="Date">Date</label>
                    <input type="date" />
                  </div>
                </div>
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-user-friends"></i>
                  </div>
                  <div class="inputs">
                    <label for="Tour Type">Tour Type</label>
                    <select name="" id="">
                      <option value="">Family Tour</option>
                      <option value="">Group Tour</option>
                      <option value="">Honeymoon Tour</option>
                      <option value="">Adventure Tour</option>
                      <option value="">Solo Tour</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="filter-btn">
                <button class="mybtn">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="categories">
          <div class="left-category-section">
            <h2>Go beyong the ordinary</h2>
            <p>
              Discover your ideal destination with the help of our travel
              experts.
            </p>
          </div>
          <div class="right-category-section">
            <div class="category-item" v-for="category in categories" :key="category.id">
              <div class="img-section">
                <img :src="$img(category.image)" alt="" />
              </div>
              <div class="text-section">
                <label for="Category">{{ category.name }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container for-pac">
        <div class="packages">
          <div class="section-header">
            <label for="Destinations" class="my-title">Explore our Tour Package</label>
            <p class="desc">
              Explore our popular travel packages and book your next trip with
              us.
            </p>
            <div class="filter-btns">
              <button :class="activeDealtab == 'Tours' ? 'active' : ''" @click="activeDealtab = 'Tours'">
                Tours
              </button>
              <button :class="activeDealtab == 'Hotels' ? 'active' : ''" @click="activeDealtab = 'Hotels'">
                Hotels
              </button>
              <button :class="activeDealtab == 'Stays' ? 'active' : ''" @click="activeDealtab = 'Stays'">
                Stays
              </button>
            </div>
          </div>
          <div class="packages-body" data-aos="fade-up" data-aos-duration="1000" v-if="activeDealtab == 'Tours'">
            <router-link :to="{
              name: 'ViewTour',
              params: { id: item.id, name: $urlCompatible(item.title) },
            }" class="package-item" v-for="item in features.tours" :key="item.attraction_id">
              <div class="content">
                <div class="img-section jc-center">
                  <img :src="$uploads(item.image)" :alt="item.title" srcset="" />
                  <div class="specs">
                    <div class="region">
                      <i class="fas fa-map-marker-alt"></i>
                      <label for="Tourism Region">{{
                        item.location.name
                      }}</label>
                    </div>
                    <div class="days">
                      <label for="Days">{{ item.duration }}</label>
                    </div>
                  </div>
                </div>
                <div class="text-section">
                  <label :for="item.location.name" class="package-title">{{
                    item.title
                  }}</label>
                </div>
                <hr />
                <div class="content-footer">
                  <div class="money">
                    <label for="Amount" v-if="item.price">
                      <span>Price Starts From </span>
                      <p>{{ $money(item.price) }} USD</p>
                    </label>
                    <label for="Amount" v-else>-</label>
                  </div>
                  <div class="book">
                    <router-link :to="{
                      name: 'ViewTour',
                      params: { id: item.id, name: $urlCompatible(item.title) },
                    }" class="book-btn">Book Now
                      <i class="fa-solid fa-arrow-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="packages-body" data-aos="fade-up" data-aos-duration="1000" v-else-if="activeDealtab == 'Hotels'">
            <router-link :to="{
              name: 'ViewHotel',
              params: {
                id: item.hotel_id,
                name: $urlCompatible(item.hotel_name),
              },
            }" class="package-item" v-for="item in features.hotels" :key="item.id">
              <div class="content">
                <div class="img-section jc-center">
                  <img :src="$uploads(item.hotel_image)" :alt="item.location.name" srcset="" />
                  <div class="specs">
                    <div class="region">
                      <i class="fas fa-map-marker-alt"></i>
                      <label for="Tourism Region">{{
                        item.location.name
                      }}</label>
                    </div>
                  </div>
                </div>
                <div class="text-section">
                  <label :for="item.hotel_name" class="package-title">{{
                    item.hotel_name
                  }}</label>
                </div>
                <hr />
                <div class="content-footer">
                  <div class="money">
                    <label for="Amount" v-if="item.price">
                      <span>Price Starts From</span>
                      <p>{{ $money(item.price) }}</p>
                    </label>
                    <label for="Amount" v-else>-</label>
                  </div>
                  <div class="book">
                    <router-link :to="'/viewPackage/' + item.hotel_id" class="book-btn">Reserve
                      <i class="fa-solid fa-arrow-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="packages-body" data-aos="fade-up" data-aos-duration="1000" v-else-if="activeDealtab == 'Stays'">
            <router-link :to="{
              name: 'ViewStay',
              params: {
                id: item.stay_id,
                name: $urlCompatible(item.stay_name),
              },
            }" class="package-item" v-for="item in features.stays" :key="item.id">
              <div class="content">
                <div class="img-section jc-center">
                  <img :src="$uploads(item.stay_image)" :alt="item.location.name" srcset="" />
                  <div class="specs">
                    <div class="region">
                      <i class="fas fa-map-marker-alt"></i>
                      <label for="Tourism Region">{{
                        item.location.name
                      }}</label>
                    </div>
                  </div>
                </div>
                <div class="text-section">
                  <label :for="item.stay_name" class="package-title">{{
                    item.stay_name
                  }}</label>
                </div>
                <hr />
                <div class="content-footer">
                  <div class="money">
                    <label for="Amount" v-if="item.price">
                      <span>Price Starts From </span>
                      <p>{{ $money(item.price) }}</p>
                    </label>
                    <label for="Amount" v-else>-</label>
                  </div>
                  <div class="book">
                    <router-link :to="'/viewPackage/' + item.stay_id" class="book-btn">Reserve
                      <i class="fa-solid fa-arrow-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="destinations">
          <div class="section-header">
            <label for="Destinations" class="my-title">Top Destinations</label>
            <p class="desc">
              Explore the beauty of Africa with Pride Adventure.
            </p>
          </div>
          <div class="destinations-sections">
            <div class="destination-item" v-for="destination in destinations" :key="destination.id">
              <div class="img-section">
                <img :src="$uploads(destination.images)" alt="" />
              </div>
              <div class="text-section on-hover">
                <label for="Destination">{{ destination.name }}</label>
              </div>
              <div class="display-text">
                <p>{{ destination.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container for-pac">
        <div class="ads">
          <div class="offer ad-item">
            <div class="ad-content">
              <h2>Special Offers – Low Season Discount (Gorilla Trekking)</h2>
              <p>
                Experience Gorilla Trekking at a Lower Price!
                Take advantage of our low-season discounts and enjoy an unforgettable gorilla trekking experience in Rwanda at a reduced cost. 
                This is the perfect time to explore Volcanoes National Park with fewer crowds and more personalized encounters with the majestic mountain gorillas.
              </p>
              <button>Read More</button>
            </div>
          </div>
          <div class="news ad-item">
            <div class="ad-content">
              <h2>Our Blogs</h2>
              <p>
                Inspiration & Travel Stories from Rwanda & Beyond
                Explore our blog for insider tips, travel guides, and firsthand experiences from travelers who have explored Rwanda and East Africa. 
                From gorilla trekking adventures to cultural deep dives, our blog has everything you need to plan your dream trip.
              </p>
              <button>View More</button>
            </div>
          </div>
          <div class="tips ad-item">
            <div class="ad-content">
              <h2>Get More Tips About Travel to Rwanda and East African Countries</h2>
              <p>
                Everything You Need to Know Before Visiting Rwanda and other East African Countries!
                From health & safety guidelines to currency exchange and cultural etiquette, 
                we provide all the essential information to ensure a hassle-free and enriching experience in Rwanda. 
              </p>
              <button>Read our travel tips and start planning today!</button>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
    <div class="partners">
      <div class="section-header">
        <h2 class="my-title">Our Partners</h2>
        <p class="desc">
          We collaborate with top organizations to bring you the best travel experiences.
        </p>
      </div>

      <div class="partners-sections">
        <div class="partner-item" v-for="partner in partners" :key="partner.id">
          <div class="img-section">
            <img :src="$uploads(partner.logo)" alt="Partner Logo" />
          </div>
          <div class="text-section on-hover">
            <h3>{{ partner.name }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>

      <div class="container for-news" v-if="news.length > 0" data-aos="fade-up">
        <div class="news-section">
          <div class="section-header">
            <label for="News" class="my-title">Latest travel news</label>
            <p class="desc">
              Get the latest news and updates from Pride Adventure.
            </p>
          </div>
          <div class="news-body">
            <div class="news-left">
              <div class="news-item" v-for="item in [...news].splice(1, 4)" :key="item.id">
                <div class="img-section">
                  <img :src="$uploads(item.image)" alt="" />
                </div>
                <div class="text-section">
                  <label for="News Title">{{ item.title }}</label>
                  <p>{{ item.slug }}</p>
                  <div class="date-section">
                    <label for="News Date">{{ $date(item.created_at) }}</label>
                  </div>
                </div>
              </div>
              <div class="news-button">
                <button>
                  See all articles
                  <i class="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </div>
            <div class="news-right">
              <div class="news-item">
                <div class="img-section">
                  <img :src="$uploads(news[0].image)" alt="" />
                </div>
                <div class="text-section">
                  <label for="News Title">{{ news[0].title }}</label>
                  <p>{{ news[0].slug }}</p>
                </div>
                <div class="date-section">
                  <label for="News Date">{{ $date(news[0].created_at) }}</label>
                </div>
                <div class="news-button">
                  <button>
                    Read full article
                    <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <clientFooter />
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      isLoading: false,
      color: "#072e4d",
      fullPage: true,
      trips: [],
      partners: [],
      slides: [],
      currentSlide: 0,
      destinations: [],
      activetab: 1,
      categories: [
        {
          id: 1,
          name: "Ultimate Tours",
          image: "tours.png",
        },
        {
          id: 2,
          name: "Great Hotels",
          image: "hotels.png",
        },
        {
          id: 3,
          name: "Comfortable Stays",
          image: "stays.jpeg",
        },
      ],
      news: [],
      activeFilterTab: "Tours",
      activeDealtab: "Tours",
      features: [],
    };
  },
  methods: {
    prev() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      } else {
        this.currentSlide = this.slides.length - 1;
      }
    },
    next() {
      // if (this.currentSlide < this.slides.length - 1) {
      this.currentSlide++;
      // } else {
      //   this.currentSlide = 0;
      // }
    },

    getPartners() {
      this.$store.dispatch("GET_PARTNER")
        .then((response) => {
          this.partners = response.data;
        })
        .catch((error) => {
          console.error("Error fetching partners:", error);
        });
    },

    getSlides() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SLIDE", {
          paginate: 100,
          page: 1,
        })
        .then((response) => {
          this.slides = response.data.data;
          this.$stoploader();
        })
        .catch((error) => {
          this.$stopLoader();
        });
    },
    getFeatures() {
      this.$store
        .dispatch("GET_FEATURED_ITEMS")
        .then((response) => {
          this.features["hotels"] = response.data.hotels;
          this.features["tours"] = response.data.tours;
          this.features["stays"] = response.data.stays;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDestinations() {
      this.$store
        .dispatch("GET_ATTRACTION", {
          paginate: 6,
          page: 1,
        })
        .then((response) => {
          this.destinations = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getNews() {
      this.$store
        .dispatch("GET_BLOG", {
          paginate: 5,
          page: 1,
        })
        .then((response) => {
          this.news = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    let vm = this;
    this.getPartners();
    this.getSlides();
    this.getFeatures();
    this.getDestinations();
    this.getNews();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/main.scss";

.home {
  width: 100vw;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    width: 100vw;
  }

  .jumbo2 {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 900px) {
      height: 100vh;
    }

    .jumbo-section {
      background: #eee;
      height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden;

      .jumboimg-body {
        height: 100%;
        width: 100%;
        position: relative;

        .img {
          position: relative;
          width: 100%;
          height: 89vh;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .text-content {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.5);
          color: white;
          padding: 2rem;

          @media screen and (max-width: 900px) {
            align-items: flex-start;
            padding-top: 30%;
          }

          .text {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            label {
              font-size: 1.5rem;
              font-weight: 600;
              color: white;
              display: flex;
              align-items: center;
              column-gap: 0.5rem;
              background: url("@/assets/images/bgog.svg") no-repeat;
              background-size: contain;
              padding: 1rem 2rem;
              margin-bottom: 2rem;

              i {
                font-size: 1.5rem;
              }
            }

            h2 {
              font-size: 4.5rem;
              font-weight: 600;
              color: white;
              width: 50%;
              line-height: 1.2;

              @media screen and (max-width: 1700px) {
                width: 75%;
                font-size: 3.8rem;
              }

              @media screen and (max-width: 1300px) {
                font-size: 3.4rem;
              }

              @media screen and (max-width: 900px) {
                font-size: 3rem;
                width: 70%;
              }

              @media screen and (max-width: 600px) {
                font-size: 2rem;
                width: 90%;
              }
            }

            p {
              font-size: 1.1rem;
              font-weight: 400;
              color: white;
              width: 50%;
              margin-bottom: 4rem;
              line-height: 1.5;

              @media screen and (max-width: 1700px) {
                width: 65%;
              }

              @media screen and (max-width: 1300px) {
                font-size: 1rem;
              }

              @media screen and (max-width: 900px) {
                width: 80%;
                font-size: 0.8rem;
              }
            }
          }

          .btns {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 1rem;
            margin-top: 1rem;
            margin-bottom: 5rem;


            .tripadvisor {
              display: flex;
              align-items: flex-start;
              column-gap: 0.5rem;

              img {
                width: 50px;
                height: 50px;
                cursor: pointer;
              }

              .right {
                display: flex;
                flex-direction: column;
                column-gap: 0.5rem;

                svg {
                  width: 100px;
                  height: 20px;
                  cursor: pointer;

                  path {
                    fill: white;
                  }
                }

                .rating {
                  display: flex;
                  align-items: center;
                  column-gap: 0.5rem;
                  cursor: pointer;

                  span {
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: white;
                  }

                  ul {
                    display: flex;
                    column-gap: 0.25rem;
                    cursor: pointer;

                    i {
                      font-size: 1rem;
                      color: #00aa6c;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .arrows {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 1rem;
        margin-bottom: 10%;

        button {
          border: 1px solid #ffffffaf;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          cursor: pointer;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            font-size: 1.5rem;
            color: #ffffff;
          }
        }
      }
    }
  }

  .filter-section {
    width: 90vw;
    margin-left: 18px;
    border-radius: 20px;
    overflow: hidden;
    margin-top: -3rem;
    z-index: 1;
    box-shadow: 0px 0px 10px 3px #4d4d4d2a;

    @media screen and (max-width: 1700px) {
      margin-top: -6rem;
    }

    @media screen and (max-width: 1300px) {
      // padding: 3rem 5rem;
    }

    @media screen and (max-width: 900px) {
      // padding: 2rem;
    }

    .top-filter {
      background: #fff;
      padding: 1rem;
      border-radius: 20px 20px 0 0;

      .filter-btns {
        display: flex;
        gap: 1rem;

        button {
          padding: 0.5rem 2rem;
          font-size: 1rem;
          font-weight: 500;
          color: $primary_color;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          transition: all 0.4s ease-in-out;
          background: transparent;
          border: 1px solid $primary_color;

          &:hover,
          &.active {
            background: $primary_color;
            color: white;
          }

          @media screen and (max-width: 1300px) {
            padding: 0.5rem 1rem;
            font-size: 0.8rem;
          }
        }
      }
    }

    .bottom-filter {
      display: flex;
      flex-direction: column;
      background: #fff;
      width: 100%;

      .filter {
        display: flex;
        width: 100%;
        gap: 1rem;
        padding: 0 2rem;

        @media screen and (max-width: 1300px) {
          padding: 0 1rem;
        }

        @media screen and (max-width: 900px) {
          padding: 0 1rem;
          flex-direction: column;
        }

        .filter-item {
          display: flex;
          padding: 1rem;
          background: #f7d9be56;
          border-radius: 20px;

          &:first-child {
            width: 40%;

            @media screen and (max-width: 900px) {
              width: 100%;
            }
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 30%;

            @media screen and (max-width: 900px) {
              width: 100%;
            }
          }

          .icon {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            i {
              font-size: 1.5rem;
              color: $primary_color;
              opacity: 0.7;
              margin-right: 1rem;
            }
          }

          .inputs {
            display: flex;
            flex-direction: column;
            width: 100%;

            label {
              font-size: 0.85rem;
              font-weight: 400;
              color: #333333;
              margin-left: 4px;
            }

            select,
            input {
              width: 100%;
              border: none;
              padding: 0;
              font-size: 1rem;
              font-weight: 500;
              color: #333333;
              background: transparent;
              outline: none;

              &:focus {
                border: none;
              }
            }
          }
        }
      }

      .filter-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;

        .mybtn {
          padding: 0.8rem 3rem;
          font-size: 1rem;
          font-weight: 500;
          color: white;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          transition: all 0.4s ease-in-out;

          @media screen and (max-width: 600px) {
            width: 100%;
          }
        }
      }
    }
  }

  .categories {
    padding: 8rem 0;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;

    @media screen and (max-width: 1300px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .left-category-section {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      h2 {
        font-size: 2.5rem;
        font-weight: 600;
        color: #333;
        text-align: left;

        &::after {
          content: "";
          display: block;
          width: 70%;
          height: 2px;
          background: $primary_color;
          margin-top: 1rem;
        }
      }

      p {
        font-size: 1.1rem;
        font-weight: 400;
        color: #777;
        text-align: left;
      }
    }

    .right-category-section {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @media screen and (max-width: 1300px) {
        grid-template-columns: repeat(1, 1fr);
      }

      gap: 1rem;

      .category-item {
        background: #fff;
        border-radius: 1.5rem;
        box-shadow: 0px 0px 10px 3px #4d4d4d2a;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
        position: relative;
        cursor: pointer;

        &:hover {
          img {
            transform: scale(1.2, 1.2);
            transition: all 0.4s ease-in-out;
          }

          .text-section {
            background: rgba(0, 0, 0, 0.5);
          }
        }

        .img-section {
          position: relative;
          height: 400px;
          width: 100%;

          @media screen and (max-width: 1300px) {
            height: 200px;
          }

          @media screen and (max-width: 1100px) {
            height: 250px;
          }

          @media screen and (max-width: 700px) {
            height: 200px;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 1.5rem;
          }
        }

        .text-section {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.178);
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;

          label {
            font-size: 1.5rem;
            font-weight: 600;
            color: white;
            display: flex;
            align-items: center;
            margin-bottom: 1.5rem;

            i {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  .partners-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.partner-item {
  background: #867b7b;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  width: 200px;
}

.img-section img {
  width: 100px;
  height: auto;
}

.text-section h3 {
  font-size: 18px;
  color: #444;
  margin-top: 10px;
}


  .destinations {
    padding: 8rem 0;
    width: 100%;
    background: url("@/assets/images/bgs-01.png") no-repeat;
    background-size: cover;

    @media screen and (max-width: 1300px) {
      padding: 5rem 0;
    }

    @media screen and (max-width: 900px) {
      padding: 1rem 0;
    }

    .destinations-sections {
      display: flex;
      gap: 1rem;
      margin: 2rem 0;
      flex-wrap: wrap;
      position: relative;

      .destination-item {
        background: #fff;
        border-radius: 1.5rem;
        box-shadow: 0px 0px 10px 3px #4d4d4d2a;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
        position: relative;
        cursor: pointer;

        &:nth-child(1) {
          width: calc(25% - 1rem);

          @media screen and (max-width: 1300px) {
            width: calc(50% - 1rem);
          }

          @media screen and (max-width: 900px) {
            width: 100%;
          }
        }

        &:nth-child(2) {
          width: 50%;

          @media screen and (max-width: 1300px) {
            width: calc(50% - 1rem);
          }

          @media screen and (max-width: 900px) {
            width: 100%;
          }
        }

        &:nth-child(3) {
          width: calc(25% - 1rem);

          @media screen and (max-width: 1300px) {
            width: calc(50% - 1rem);
          }

          @media screen and (max-width: 900px) {
            width: 100%;
          }
        }

        &:nth-child(4) {
          width: calc(50% - 1rem);

          @media screen and (max-width: 1300px) {
            width: calc(50% - 1rem);
          }

          @media screen and (max-width: 900px) {
            width: 100%;
          }
        }

        &:nth-child(5) {
          width: 25%;

          @media screen and (max-width: 1300px) {
            width: calc(50% - 1rem);
          }

          @media screen and (max-width: 900px) {
            width: 100%;
          }
        }

        &:nth-child(6) {
          width: calc(25% - 1rem);

          @media screen and (max-width: 1300px) {
            width: calc(50% - 1rem);
          }

          @media screen and (max-width: 900px) {
            width: 100%;
          }
        }

        &:nth-child(7) {
          width: calc(44.5% - 1rem);

          @media screen and (max-width: 1300px) {
            width: 100%;
          }

          @media screen and (max-width: 900px) {
            width: 100%;
          }
        }

        &:nth-child(8) {
          @media screen and (max-width: 1300px) {
            width: 100%;
          }

          @media screen and (max-width: 900px) {
            width: 100%;
          }
        }

        .text-section {
          position: absolute;
          bottom: -400px;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 1rem;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          label {
            font-size: 1.5rem;
            font-weight: 600;
            color: white;
            display: flex;
            align-items: center;

            i {
              font-size: 1.5rem;
            }
          }

          p {
            font-size: 0.6rem;
            font-weight: 400;
            color: white;
            background: url("@/assets/images/bgo.svg") no-repeat;
            background-size: contain;
            padding: 1rem 2rem;
          }
        }

        .img-section {
          position: relative;
          height: 300px;
          width: 100%;

          @media screen and (max-width: 1300px) {
            height: 350px;
          }

          @media screen and (max-width: 1100px) {
            height: 250px;
          }

          @media screen and (max-width: 700px) {
            height: 200px;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 1.5rem;
          }
        }

        .display-text {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          background: rgba(0, 0, 0, 0.233);

          p {
            font-size: 1.5rem;
            font-weight: 500;
            color: #fff;
            padding: 0rem 2rem;
          }
        }

        &:hover,
        &:focus {
          transform: scale(1, 1);
          z-index: 1;

          .text-section {
            transition: all 0.4s ease-in-out;
            bottom: 0px;
          }

          .display-text {
            display: none;
          }

          img {
            transform: scale(1.2, 1.2);
            transition: all 0.4s ease-in-out;
          }
        }
      }

      .view-all {
        background: #fff;
        border-radius: 1.5rem;
        box-shadow: 0px 0px 10px 3px #4d4d4d2a;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
        background: url("@/assets/images/bgs-03.png") no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover,
        &:focus {
          transform: scale(1, 1);
          z-index: 1;
        }

        .img-section {
          position: relative;

          img {
            height: 250px;
            width: 100%;
            object-fit: cover;
            border-radius: 1.5rem;

            @media screen and (max-width: 1300px) {
              height: 200px;
            }

            @media screen and (max-width: 1100px) {
              height: 250px;
            }

            @media screen and (max-width: 700px) {
              height: 200px;
            }
          }
        }

        .display-text {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          p {
            font-size: 1.2rem;
            font-weight: 500;
            color: #1d1d1d;
          }
        }
      }
    }
  }

  .for-pac {
    background: #f7ebe0;
    height: 50%;
  }

  .ads {
    padding: 8rem 0;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 1rem;

    @media screen and (max-width: 1300px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .ad-item {
      height: 600px;
      border-radius: 20px;
      overflow: hidden;

      @media screen and (max-width: 1300px) {
        height: 400px;
      }

      @media screen and (max-width: 900px) {
        height: 300px;
      }

      &:nth-child(1) {
        background: url("@/assets/images/gor1.jpg") no-repeat;
        background-size: cover;
        background-position: center;
      }

      &:nth-child(2) {
        background: url("@/assets/images/bg1.jpg") no-repeat;
        background-size: cover;
        background-position: center;
      }

      &:nth-child(3) {
        background: url("@/assets/images/bg2.jpg") no-repeat;
        background-size: cover;
        background-position: center;
      }

      .ad-content {
        background: rgba(0, 0, 0, 0.24);
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 2rem;

        h2 {
          font-size: 2.5rem;
          font-weight: 600;
          color: white;
          text-align: left;
          margin-bottom: 1rem;

          @media screen and (max-width: 900px) {
            font-size: 1.5rem;
          }
        }

        p {
          font-size: 1.2rem;
          font-weight: 400;
          color: white;
          text-align: left;
          margin-bottom: 2rem;

          @media screen and (max-width: 900px) {
            font-size: 1rem;
          }
        }

        button {
          padding: 1rem 2rem;
          font-size: 1.2rem;
          font-weight: 500;
          color: white;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          transition: all 0.4s ease-in-out;
          background: $primary_color;

          &:hover {
            background: #333;
          }

          @media screen and (max-width: 900px) {
            font-size: 1rem;
            padding: 0.5rem 1rem;
          }
        }
      }
    }
  }

  .news-section {
    padding: 8rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (max-width: 1300px) {
      padding: 5rem 0;
    }

    @media screen and (max-width: 900px) {
      padding: 1rem 0;
    }

    .news-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 1.5rem;

      @media screen and (max-width: 1300px) {
        grid-template-columns: repeat(1, 1fr);
        flex-flow: column-reverse;
      }

      .news-left {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 0 3rem;

        .news-item {
          display: grid;
          grid-template-columns: 110px calc(100% - 110px);
          gap: 1rem;

          .img-section {
            height: 100px;
            width: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
            }
          }

          .text-section {
            display: flex;
            flex-direction: column;

            // justify-content: space-between;
            label {
              font-size: 1.4rem;
              font-weight: 600;
              color: #333;
              display: flex;
              column-gap: 0.5rem;
              background-size: contain;
              margin-bottom: 0.3rem;

              i {
                font-size: 1.5rem;
              }
            }

            p {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              font-size: 0.95rem;
              color: #444;
            }

            .date-section {
              label {
                font-size: 0.9rem;
                font-weight: 400;
                color: $primary_color;
              }
            }
          }
        }

        .news-button {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0rem;

          button {
            font-size: 1.1rem;
            font-weight: 500;
            color: $primary_color;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.4s ease-in-out;
            background: transparent;
            padding: 0;

            &:hover {
              font-weight: 600;
            }
          }
        }
      }

      .news-right {
        padding: 0 3rem;

        @media screen and (max-width: 1300px) {
          grid-column-start: 1;
          grid-row-start: 1;
          margin-bottom: 2rem;
        }

        .news-item {
          .img-section {
            height: 300px;
            width: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 5px;
            }
          }

          .text-section {
            label {
              font-size: 1.4rem;
              font-weight: 600;
              color: #333;
              display: flex;
              column-gap: 0.5rem;
              background-size: contain;
              margin: 1rem 0;

              i {
                font-size: 1.5rem;
              }
            }

            p {
              font-size: 1.2rem;
              font-weight: 400;
              color: #777;
              margin-bottom: 1rem;
              line-height: 1.5;
            }
          }
        }

        .news-button {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0rem;
          margin-top: 1rem;

          button {
            font-size: 1.1rem;
            font-weight: 500;
            color: $primary_color;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.4s ease-in-out;
            background: transparent;
            padding: 0;

            &:hover {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
</style>
